import React, { useEffect, useState } from 'react';
import ProjectCards from './ProjectCards';
import './project.css';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import {
    Input, Button, Modal, ModalBody
} from 'reactstrap';
import axios from 'axios';
import { GrSearch } from "react-icons/gr";
import FloatingBtn from '../../common/Buttons/FloatingBtn';
import Pagination from '../manageMaster/common/cardPagination';
import {
    GET_NEW_ARRVAL_PRO, GET_PROJECT_COUNT, GET_ALL_PROJECTS, DELETE_PROJECT, GET_allprojects, GET_fillingprojects, GET_rejectedProjects
    , GET_runningprojects, GET_completeprojects, GET_DraftProjects, ALLOWED_USERTYPE, GET_ALL_RESULT_AWAITED
} from '../../utiles/AllApis';
import SideBar from '../../common/SideBar/SideBar';
import { VscError } from 'react-icons/vsc';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import { useNavigate } from 'react-router-dom';
import DataGrids from '../../common/table/DataGrid';
import { MdFormatLineSpacing, MdOutlineStorage } from 'react-icons/md';
const ProjectsList = (props) => {
    const [isTblView, setIsTblView] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setfilter] = useState("");
    const [cardLength, setCardLength] = useState(0);
    const [showParPage, setShowParPage] = useState(10);
    const [heading, setHeading] = useState("");
    const navigate = useNavigate();
    const [docList, setDocList] = useState([]);
    const [isOpenError, setIsOpenError] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [cards, setCards] = useState();
    const [newProject, setnewProject] = useState([]);
    const [allCards, setAllCards] = useState([]);
    const [newCards, setNewCards] = useState([]);
    const [filingCards, setFilingCard] = useState([]);
    const [runningCards, setRunningCards] = useState([]);
    const [completeCards, setCompleteCards] = useState([]);
    const [newArrCards, setNewArrCards] = useState([]);
    const [rejectCards, setRejectCards] = useState([]);
    const [draftCards, setDraftCards] = useState([]);
    const [colorCls, setColorCls] = useState("masterbtnCls");
    const [cardsArray, setCardsArray] = useState([]);
    const [deleteId, setDeleteID] = useState("");
    const [isOpen1, setIsOpen1] = useState(false);
    const handalOpen1 = () => { setIsOpen1(true); }
    const [ispage, setIspage] = useState(true);
    const [searctVal, setSearctVal] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [proType, setProType] = useState("");
    const [heading1, setHeading1] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [NewCount, setNewCount] = useState(0)
    const [Considering, setConsideringcount] = useState(0)
    const [Filing, setFiling] = useState(0);
    const [Running, setRunning] = useState(0)
    const [Completed, setCompleted] = useState(0)
    const [Rejected, setRejectedCard] = useState(0)
    const [newProjectcount, setNewProjectCount] = useState(0)
    const [resultAwaitedCount, setResultAwaitedCount] = useState(0)
    const [resultAwaited, setResultAwaited] = useState([]);
    const [resultAwaitedCards, setResultAwaitedCards] = useState([])
    const [Draft, setDraft] = useState(0)
    const handalOpen = () => { setIsOpen(true); }
    const handalClose = () => { setIsOpen(false); }
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showParPage
        }
    );

    const projectList = [
        {
            "MenuName": `(${NewCount})-New Arrival`,
            "href": `newArrival`
        },
        {
            "MenuName": `(${newProjectcount})-Considering`,
            "href": `new`
        },
        {
            "MenuName": `(${Filing})-Filing`,
            "href": "filing"
        },
        {
            "MenuName": `(${resultAwaitedCount})-Result Awaited`,
            "href": `resultAwaited`
        },
        {
            "MenuName": `(${Running})-Running(Won)`,
            "href": "running"
        },
        {
            "MenuName": `(${Completed})-Completed`,
            "href": "completed"
        }
        ,
        {
            "MenuName": `(${Rejected})-Rejected`,
            "href": "rejected"
        },
        {
            "MenuName": `(${Draft})-Draft`,
            "href": "draft"
        }
    ]
    const projectListOperation = [
        {
            "MenuName": "New Tenders",
            "href": `new`
        },
        {
            "MenuName": "Running",
            "href": "running"
        },
        {
            "MenuName": "Completed",
            "href": "completed"
        }

    ]
    const projectListProjects = [
        {
            "MenuName": "New Tenders",
            "href": `new`
        },
        {
            "MenuName": "Filing",
            "href": "filing"
        },
        {
            "MenuName": `Result Awaited`,
            "href": `new`
        },
        {
            "MenuName": "Running",
            "href": "running"
        },
        {
            "MenuName": "Completed",
            "href": "completed"
        },
        {
            "MenuName": "Rejected",
            "href": "rejected"
        }

    ]
    //column for grid view for New Project

    const columnsForNewPro = [
        {
            field: "edate",
            headerName: "Created At",
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                return (
                    <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
                );
            }
        },
        { field: 'rowpos', headerName: 'Project Id' },
        { field: 'projectName', headerName: 'Project Name', width: 150 },
        { field: 'clientName', headerName: 'Client Name', width: 130 },
        { field: 'category', headerName: 'Category', width: 140 },
        { field: 'ownerName', headerName: 'Owner Name', width: 130 },
        { field: 'projectType', headerName: 'Project Type', width: 130 },
        {
            field: "ldateOfSubmissionBid",
            headerName: "Last date Of Submission Bid",
            width: 100,
            disableClickEventBubbling: true,
            renderCell:
                (params) => {
                    return (
                        <p className='text-white mt-2 f-14'>{params?.row?.ldateOfSubmissionBid?.split("T")[0]}</p>
                    );
                }
        },
        {
            field: "vIEW",
            headerName: "Eligibility Document",
            sortable: false,
            width: 105,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.eligibilitY_DOC === "" || params.row.eligibilitY_DOC === null) {
                    return (
                        <p className='text-white'>--Not Found--</p>
                    )
                }
                else {
                    return (
                        <Button variant="contained btnSuccessClr" ><a className='text-white' href={params.row.eligibilitY_DOC} target="_blank">view</a></Button>
                    );
                }
            }
        },
        {
            field: "VIEW",
            headerName: "Project Document",
            width: 105,
            renderCell: (params) => {
                if (params.row.projecT_DOC === "" || params.row.projecT_DOC === null) {
                    return (
                        <p className='text-white'>--Not Found--</p>
                    )
                }
                else {
                    return (
                        <Button variant="contained btnSuccessClr" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }}><a className='text-white' href={params.row.projecT_DOC} target="_blank">view</a></Button>
                    );
                }
            }
        }
    ]
    const deleteProject = () => {
        handalClose();
        setLoading(true);
        axios.post(DELETE_PROJECT, { "ROWPOS": deleteId }).then((response) => {
            setLoading(false);
            if (response.data.IsSuccess === true) {
                setHeading1("Success");
                setsuccessMsg("Project Deleted Successfully.");
                handalOpen1();
            }
            else {
                setHeading("Error")
                setErrMsg(`Opps..Fail to delete`);
                setIsOpenError(true);
            }
        });
    }
    const onDelete = (id) => {
        setDeleteID(id);
        handalOpen();
    }
    const paginationChange = (start, end) => {
        setIspage(false)
        setPagination({ start: start, end: end });
    }
    const searchFilterData = (e) => {

        setSearctVal(e.target.value);
        // getAllProjectForSearch();
    }


    useEffect(() => {


        getAllProCount()
    }, [])

    const getAllProCount = () => {
        setLoading(true);
        axios.get(GET_PROJECT_COUNT).then((response) => {

            if (response.data.isSuccess === true) {
                console.log(response.data.data)
                // setCards(response.data.data);
                setNewCount(response.data.data[0].count)
                setResultAwaitedCount(response.data.data[2].count)
                setNewProjectCount(response.data.data[7].count)
                setFiling(response.data.data[1].count)
                setRunning(response.data.data[3].count)
                setCompleted(response.data.data[4].count)
                setRejectedCard(response.data.data[5].count)
                setDraft(response.data.data[6].count)
            }
            else {
                setCards(<p className='my-5'>No Projects Found!!</p>)
            }
        });
    }


    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true); // Show loader initially
    //         setCardLength(0);

    //         const proType = window.location.href.split("=")[window.location.href.split("=").length - 1];
    //         setProType(proType);

    //         try {
    //             let response;
    //             let mappedData;

    //             if (proType === "newArrival" || proType === "newArrival#") {
    //                 response = await axios.get(GET_NEW_ARRVAL_PRO);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setNewCount(mappedData.length);
    //                     setNewArrCards(mappedData);
    //                 }
    //             } else if (proType === "new" || proType === "new#") {
    //                 response = await axios.get(GET_allprojects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setNewCards(mappedData);
    //                 }
    //             } else if (proType === "filing" || proType === "filing#") {
    //                 response = await axios.get(GET_fillingprojects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setFilingCard(mappedData);
    //                 }
    //             } else if (proType === "running" || proType === "running#") {
    //                 response = await axios.get(GET_runningprojects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setRunningCards(mappedData);
    //                 }
    //             } else if (proType === "completed" || proType === "completed#") {
    //                 response = await axios.get(GET_completeprojects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data.filter(
    //                         (data) => data.lst_InternalDocuments.length > 0 || data.lost === true
    //                     );
    //                     setCompleteCards(mappedData);
    //                 }
    //             } else if (proType === "rejected" || proType === "rejected#") {
    //                 response = await axios.get(GET_rejectedProjects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setRejectCards(mappedData);
    //                 }
    //             } else if (proType === "draft" || proType === "draft#") {
    //                 response = await axios.get(GET_DraftProjects);
    //                 if (response.data.isSuccess) {
    //                     mappedData = response.data.data;
    //                     setDraftCards(mappedData);
    //                 }
    //             }

    //             setCardLength(mappedData?.length || 0);
    //         } catch (error) {
    //             console.error("Error fetching data", error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, []);



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setCardLength(0);

            const proType = window.location.href.split("=")[window.location.href.split("=").length - 1];
            setProType(proType);

            try {
                let response;
                let mappedData = [];

                if (proType === "newArrival" || proType === "newArrival#") {
                    response = await axios.get(GET_NEW_ARRVAL_PRO);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setNewCount(mappedData.length);
                        setNewArrCards(mappedData);
                    }
                } else if (proType === "new" || proType === "new#") {
                    response = await axios.get(GET_allprojects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setNewCards(mappedData);
                    }
                }
                else if (proType === "resultAwaited" || proType === "resultAwaited#") {
                    response = await axios.get(GET_ALL_RESULT_AWAITED);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setResultAwaitedCards(mappedData);
                    }
                }

                else if (proType === "filing" || proType === "filing#") {
                    response = await axios.get(GET_fillingprojects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setFilingCard(mappedData);
                    }
                } else if (proType === "running" || proType === "running#") {
                    response = await axios.get(GET_runningprojects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setRunningCards(mappedData);
                    }
                } else if (proType === "completed" || proType === "completed#") {
                    response = await axios.get(GET_completeprojects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data.filter(
                            (data) => data.lst_InternalDocuments.length > 0 || data.lost === true
                        );
                        setCompleteCards(mappedData);
                    }
                } else if (proType === "rejected" || proType === "rejected#") {
                    response = await axios.get(GET_rejectedProjects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setRejectCards(mappedData);
                    }
                } else if (proType === "draft" || proType === "draft#") {
                    response = await axios.get(GET_DraftProjects);
                    if (response.data.isSuccess) {
                        mappedData = response.data.data;
                        setDraftCards(mappedData);
                    }
                }

                setCardLength(mappedData?.length || 0);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false); // Hide loader only after processing is complete
            }
        };

        fetchData();
    }, []);



    const getAllProjectForSearch = () => {
        // setLoading(true);
        axios.get(GET_ALL_PROJECTS).then((response) => {
            // setLoading(false);
            if (response.data.isSuccess === true) {
                {
                    setFilingCard(response.data.data);
                    setCardLength(response?.data?.data.length);
                }
            }
            else {
                setCards(<p className='my-5'>No Projects Found!!</p>)
            }
        });
    }


    const filterProject = async (val) => {

        window.location.replace(`/projectList?type=${val}`);
        setCardLength(0);
        if (val === "new") {
            setLoading(true);
            await axios.get(GET_allprojects).then((response) => {

                if (response.data.isSuccess === true) {
                    setCardLength(response?.data?.data?.length);
                    setNewCards(response.data.data);
                    setLoading(false);
                }
                else {
                    // setNewCards(<p className='my-5'>No Projects Found!!</p>)
                    setCardLength(0);
                }
            });
        }
        else if (val === "filing") {
            setLoading(true);
            axios.get(GET_fillingprojects).then((response) => {

                if (response.data.isSuccess === true) {

                    setFilingCard(response.data.data);
                    setCardLength(response?.data?.data.length);
                    setLoading(false);
                }
                else {
                    // setFilingCard(<p className='my-5'>No Projects Found!!</p>)
                    setCardLength(0);
                }
            });
        }
        else if (val === "running") {
            setLoading(true);
            axios.get(GET_runningprojects).then((response) => {

                if (response.data.isSuccess === true) {
                    // var dataRunning= response.data.data.filter((data=>{
                    //     if(data.lst_InternalDocuments.length===0)
                    //     {
                    //         return data; 
                    //     }
                    // }))
                    setRunningCards(response.data.data);
                    setCardLength(response.data.data.length);
                    setLoading(false);
                }
                else {
                    setCardLength(0);
                    // setRunningCards(<p className='my-5'>No Projects Found!!</p>)
                }
            });
        }
        else if (val === "resultAwaited") {
            setLoading(true);
            axios.get(GET_ALL_RESULT_AWAITED).then((response) => {

                if (response.data.isSuccess === true) {
                    // var dataRunning= response.data.data.filter((data=>{
                    //     if(data.lst_InternalDocuments.length===0)
                    //     {
                    //         return data; 
                    //     }
                    // }))
                    setResultAwaited(response.data.data);
                    setCardLength(response.data.data.length);
                    setLoading(false);
                }
                else {
                    setCardLength(0);
                    // setRunningCards(<p className='my-5'>No Projects Found!!</p>)
                }
            });
        }
        else if (val === "completed") {
            setLoading(true);
            axios.get(GET_completeprojects).then((response) => {

                if (response.data.isSuccess === true) {
                    var dataCom = response.data.data.filter((data => {
                        if (data.lst_InternalDocuments.length > 0 || data.lost === true) {
                            return data;
                        }
                    }))
                    setCompleteCards(dataCom);
                    setCardLength(response?.data?.data.length);
                    setLoading(false);
                }
                else {
                    setCardLength(0);
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
            });
        }
        else if (val === "newArrival") {
            setLoading(true);
            axios.get(GET_NEW_ARRVAL_PRO).then((response) => {

                if (response.data.isSuccess === true) {
                    var dataCom = response.data.data
                    newArrCards(dataCom);
                    setCardLength(response?.data?.data.length);
                    setLoading(false);
                }
                else {
                    setCardLength(0);
                    // setCompleteCards(<p className='my-5'>No Projects Found!!</p>)
                }
            });
        }
        else if (val === "rejected") {

            setLoading(true);
            axios.get(GET_rejectedProjects).then((response) => {

                if (response.data.isSuccess === true) {
                    setRejectCards(response.data.data);
                    setCardLength(response.data.data.length);
                }
                else {
                    setCardLength(0);
                    setCards(<p className='my-5'>No Projects Found!!</p>)
                }
                setLoading(false);
            });
        }
    }
    return (
        <>   {
            isLoading ? <div className='loaderDiv'>
            </div> : null
        }
            <div className='container-fluid'>
                <div className='row '>
                    {
                        props?.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?
                            <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
                                <SideBar />
                            </div> : null
                    }

                    <div className={`${props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN
                        ? 'col-md-10 col-10 col-sm-10 '
                        : 'col-md-12 col-12 col-sm-12'} myview`}>
                        <div className='row px-2' >
                            {/*for Admin type */}
                            {
                                props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?

                                    projectList.map((data, i) => {
                                        return (<div key={i} className={`col-md-3 col-sm-3 col-3 text-center my-2 py-1 newShdBtn ${proType === data.href || proType === `${data.href}#` ? 'activeCls' : ''}`}  ><button id={data.href} className={"btn text-white"} onClick={e => { filterProject(data.href) }}>{data.MenuName} </button></div>)
                                    }) :
                                    ""
                            }
                            {/*for operation type */}
                            {
                                props.tokectDetails?.logintype === ALLOWED_USERTYPE.OPERATIONS ?
                                    projectListOperation.map((data, i) => {
                                        return (<><div key={i} className={`col-md-1 col-sm-1 col-1`}></div>
                                            <div key={i} className={`col-md-2 col-sm-2 col-3 text-center my-2 py-1 newShdBtn ${proType === data.href || proType === `${data.href}#` ? 'activeCls' : ''}`}  ><button id={data.href} className={"btn text-white"} onClick={e => { filterProject(data.href) }}>{data.MenuName} </button></div></>)
                                    }) :
                                    ""
                            }
                            {/*for Projects type */}
                            {
                                props.tokectDetails?.logintype === ALLOWED_USERTYPE.PROJECTS ?
                                    <><div className={`col-md-1 col-sm-1 col-1`}></div>
                                        {
                                            projectListProjects.map((data, i) => {
                                                return (<>
                                                    <div key={i} className={`col-md-2 col-sm-2 col-3 text-center my-2 py-1 newShdBtn ${proType === data.href || proType === `${data.href}#` ? 'activeCls' : ''}`}  >
                                                        <button id={data.href} className={"btn text-white"} onClick={e => { filterProject(data.href) }}>{data.MenuName}
                                                        </button>
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </>
                                    :
                                    ""
                            }
                        </div>

                        <div className='mx-4 my-1
 '>
                            <div className='row'>
                                <h4 className='py-3 text-center theamColor mailHeading heading h4 text-capitalize'>
                                    {proType === "newArrival" ? "New Arrival" : (proType === "new" ? "Under Consideration" : (proType === 'running' ? "Running(Won)" : (proType === 'resultAwaited' ? "Result Awaited" : (proType))))} Projects        </h4>


                                <div className='row'>
                                    <div className='col-md-8'>
                                        {
                                            isTblView ?
                                                <MdFormatLineSpacing onClick={e => { setIsTblView(false) }} className="h4 mt-2 text-white" />
                                                :
                                                <MdOutlineStorage onClick={e => { setIsTblView(true) }} className="h4 mt-2 text-white" />
                                        }
                                    </div>
                                    <div className='col-md-4 col-11 col-sm-11 my-2 px-3'>
                                        {
                                            isTblView ? null :
                                                <>
                                                    <GrSearch className='searchIconNew' />
                                                    <Input
                                                        id="seaarch"
                                                        name="search"
                                                        placeholder="Search Here...."
                                                        type="text"
                                                        //   value={filter}
                                                        //   onChange={searchcard}
                                                        onChange={searchFilterData}
                                                    />
                                                </>
                                        }

                                    </div>
                                </div>
                                {
                                    proType === "new" || proType === "new#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"New_Projects"} cardsList={newCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">{
                                                newCards?.length > 0 ?
                                                    newCards?.filter((data => {

                                                        if (!searctVal) {
                                                            return data;
                                                        }
                                                        else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                            return data;
                                                        }
                                                    }))
                                                        ?.map((data, i) => {
                                                            return (<div key={i} className='col-sm-4 col-md-3 col-12'>
                                                                <ProjectCards tab={"CONSIDERING PROJECTS"} onDelete={onDelete} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} ldateOfSubmissionBid={data.ldateOfSubmissionBid} />
                                                            </div>)
                                                        }) :
                                                    <p className='my-5'>No Projects Found!!</p>

                                            }

                                            </div> : null
                                }    {
                                    proType === "filing" || proType === "filing#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"Filing_Projects"} cardsList={filingCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">{
                                                filingCards?.length > 0 ?
                                                    filingCards?.filter((data => {

                                                        if (!searctVal) {
                                                            return data;
                                                        }
                                                        else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                            return data;
                                                        }
                                                    }))


                                                        ?.map((data, i) => {
                                                            return (<div key={i} className='col-sm-4 col-md-3 col-12'>
                                                                <ProjectCards tab={"FILING PROJECTS"} desc={data.projectDescription} onDelete={onDelete} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} ldateOfSubmissionBid={data.ldateOfSubmissionBid} />
                                                            </div>)
                                                        }) :
                                                    <p className='my-5'>No Projects Found!!</p>
                                            }

                                            </div> : null
                                }

                                {
                                    proType === "resultAwaited" || proType === "resultAwaited#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"Result_Awaited"} cardsList={resultAwaitedCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">{
                                                resultAwaitedCards?.length > 0 ?
                                                    resultAwaitedCards?.filter((data => {

                                                        if (!searctVal) {
                                                            return data;
                                                        }
                                                        else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                            return data;
                                                        }
                                                    }))

                                                        ?.map((data, i) => {
                                                            return (<div key={data.rowpos} className='col-sm-4 col-md-3 col-12'>
                                                                <ProjectCards tab={"RESULT_AWAITED PROJECTS"} tokectDetails={props.tokectDetails} onDelete={onDelete} page={i} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} />
                                                            </div>)
                                                        }) :
                                                    <p className='my-5'>No Projects Found!!</p>
                                            }

                                            </div> : null
                                }

                                {
                                    proType === "running" || proType === "running#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"Running_Projects"} cardsList={runningCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">{
                                                runningCards?.length > 0 ?
                                                    runningCards?.filter((data => {

                                                        if (!searctVal) {
                                                            return data;
                                                        }
                                                        else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                            return data;
                                                        }
                                                    }))

                                                        ?.map((data, i) => {
                                                            return (<div key={data.rowpos} className='col-sm-4 col-md-3 col-12'>
                                                                <ProjectCards tab={"RUNNING(WON) PROJECTS"} tokectDetails={props.tokectDetails} onDelete={onDelete} page={i} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} />
                                                            </div>)
                                                        }) :
                                                    <p className='my-5'>No Projects Found!!</p>
                                            }

                                            </div> : null
                                }
                                {
                                    proType === "completed" || proType === "completed#" ?
                                        isTblView ?
                                            <>
                                                <DataGrids name={"Completed_projects"} cardsList={completeCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">
                                                {
                                                    completeCards?.length > 0 ?
                                                        completeCards?.filter((data => {

                                                            // .filter((
                                                            //     data => {
                                                            //     if (data.lst_InternalDocuments.length > 0 || data.lost === true) {
                                                            //         return data;
                                                            //     }
                                                            // }))?

                                                            if (!searctVal) {
                                                                return data;
                                                            }
                                                            else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                return data;
                                                            }
                                                        }))
                                                            ?.map((data, i) => {
                                                                return (<div key={i} className='col-sm-4 col-md-3 col-12'>

                                                                    <ProjectCards tab={"COMPLETED PROJECT"} i={data.lst_InternalDocuments.length} onDelete={onDelete} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} Lost={data.lost} />
                                                                </div>)
                                                            }) :
                                                        <p className='my-5'>No Projects Found!!</p>
                                                }

                                            </div> : null
                                }

                                {isLoading ? (
                                    <div className="loader">Loading...</div>
                                ) : (
                                    <>
                                        {proType === "newArrival" || proType === "newArrival#" ? (
                                            isTblView ? (
                                                <DataGrids
                                                    name={"New_Arrivals_Project"}
                                                    cardsList={newArrCards}
                                                    columns={columnsForNewPro}
                                                />
                                            ) : (
                                                <div className="row">
                                                    {newArrCards?.length > 0 ? (
                                                        newArrCards
                                                            ?.filter((data) => {
                                                                if (!searctVal) return data;
                                                                if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) return data;
                                                            })
                                                            ?.map((data, i) => (
                                                                <div key={i} className="col-sm-4 col-md-3 col-12">
                                                                    <ProjectCards
                                                                        tab={"NEW ARRIVAL PROJECT"}
                                                                        i={data.lst_InternalDocuments.length}
                                                                        onDelete={onDelete}
                                                                        rowpos={data.rowpos}
                                                                        img={data.logo}
                                                                        projectName={data.projectName}
                                                                        projectDetails={data.category}
                                                                        created={data.edate}
                                                                        Lost={data.lost}
                                                                    />
                                                                </div>
                                                            ))
                                                    ) : (
                                                        <p className="my-5">No Projects Found!!</p>
                                                    )}
                                                </div>
                                            )
                                        ) : null}
                                    </>
                                )}



                                {
                                    proType === "rejected" || proType === "rejected#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"Rejected_Project"} cardsList={rejectCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">
                                                {

                                                    rejectCards?.length > 0 ?
                                                        rejectCards?.filter((data => {

                                                            if (!searctVal) {
                                                                return data;
                                                            }
                                                            else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                return data;
                                                            }
                                                        }))
                                                            ?.map((data, i) => {
                                                                return (<div key={i} className='col-sm-4 col-md-3 col-12'>

                                                                    <ProjectCards tab={"REJECTED PROJECTS"} i={data.lst_InternalDocuments.length} onDelete={onDelete} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} />
                                                                </div>)
                                                            }) :
                                                        <p className='my-5'>No Projects Found!!</p>
                                                }

                                            </div> : null
                                }

                                {
                                    proType === "draft" || proType === "draft#" ?
                                        isTblView ?
                                            <>  <DataGrids name={"Drafts_Project"} cardsList={draftCards} columns={columnsForNewPro} />
                                            </> :
                                            <div className="row">
                                                {
                                                    draftCards?.length > 0 ?
                                                        draftCards?.filter((data => {
                                                            if (!searctVal) {
                                                                return data;
                                                            }
                                                            else if (data?.projectName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                return data;
                                                            }
                                                        }))
                                                            ?.map((data, i) => {
                                                                return (<div key={i} className='col-sm-4 col-md-3 col-12'>
                                                                    <ProjectCards tab={"DRAFT PROJECTS"} page={"DRAFT"} i={data.lst_InternalDocuments.length} onDelete={onDelete} rowpos={data.rowpos} img={data.logo} projectName={data.projectName} projectDetails={data.category} created={data.edate} />
                                                                </div>)
                                                            }) :
                                                        <p className='my-5'>No Projects Found!!</p>
                                                }

                                            </div> : null
                                }
                                <div className='my-3'>
                                    <FloatingBtn href='/projectList/form' usehtmlFor="Add Project" />
                                </div>
                            </div>
                            <Modal className="fade show" isOpen={isOpen} >
                                <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
                                    <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
                                    <div>   <Button
                                        className='btnSuccess mx-2'
                                        onClick={() => { deleteProject() }}
                                    >
                                        Yes
                                    </Button>
                                        <Button
                                            className='btnDelete mx-2'
                                            onClick={handalClose}
                                        >
                                            No
                                        </Button></div>
                                </ModalBody>

                            </Modal>
                            {/*Error Box*/}
                            <ErrorAlertBox
                                isOpen={isOpenError}
                                setIsOpen={setIsOpenError}
                                heading={heading}
                                errMsg={errMsg}
                            />
                            <SuccessAlertBox href={window.location.href} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
                        </div>
                    </div>
                </div>
            </div></>


    )
}
export default ProjectsList;