import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from 'react-router-dom';
import { GET_ALL_PROJECTS, GET_runningprojects } from '../../utiles/AllApis';

function DashDesign() {
    const [isLoading, setLoading] = useState(false);
    const [viewAllPro, setViewAllPro] = useState(false);
    const [one, setone] = useState(66);
    const [HunprojectsCard, setHunprojectsCard] = useState([]);
    const [projectsCard, setprojectsCard] = useState([]);
    const [running, setrunning] = useState([]);
    const [toDoDate, setToDoDate] = useState("");
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const projectsCardForDashboard = [];
    var runningpro = [];
    var array = [];
    useEffect(() => {
        setLoading(true);
        axios.get(GET_runningprojects).then((response) => {

            if (response.data.isSuccess === true) {
                // var dataRunning= response.data.data.filter((data=>{
                //     if(data.lst_InternalDocuments.length===0)
                //     {
                //         return data; 
                //     }
                //     }))
                setrunning(response.data.data);

            }
        });
        axios.get(GET_ALL_PROJECTS).then((response) => {
            if (response.data.isSuccess === true) {
                setLoading(false);

                const data = response.data.data;


                // const fromDate = new Date("2024-12-01");
                // const toDate = new Date("2024-12-02");


                const filteredData = data.filter(item => {
                    const itemDate = new Date(item.date);
                    return itemDate >= fromDate && itemDate <= toDate;
                });

                setprojectsCard(filteredData);


                const limitedArray = filteredData.slice(0, 100);
                setHunprojectsCard(limitedArray);
            }

        })
    }, []);
    const handleViewAllPro = () => {
        viewAllPro ? setViewAllPro(false) : setViewAllPro(true);
        // updateData()


    }



    return (
        <>
            {isLoading ? <div className="loaderDiv"></div> : null}
            <div className='dashboard_main'>

                <div className='main_card_box box_1' style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>All Projects</h4>


                        {
                            viewAllPro ? <button onClick={handleViewAllPro}>View Top Hundred</button> :
                                <button onClick={handleViewAllPro}>View all</button>
                        }
                    </div>

                    <div id="" className='inner'>
                        {/* <div className='allproject_shadow_card p-4'>
                            <div className='d-flex' style={{ justifyContent: "space-around" }}>
                                <div>
                                    <label style={{ color: "white" }}> FROM DATE : </label>
                                    <input type="date" id="from_date" max="9999-12-31" value={fromDate} onChange={e => { setFromDate(e.target.value) }} />
                                    {/* <input type="time" /> */}
                        {/* /* </div>
                    <div >
                        <label style={{ color: "white" }}>TO DATE : </label>
                        <input type="date" id="To_date" max="9999-12-31" value={toDate} onChange={e => { setToDate(e.target.value) }} />
            //             {/* <input type="time" />} */}
                        {/* //         </div>
            //     </div>
            // </div> */ }
                        {
                            viewAllPro ?
                                projectsCard?.map((data, i) => {
                                    return (

                                        <>
                                            <Link key={i} to={`/projectList/${data.project_ID}`} style={{ textDecoration: 'none' }}>
                                                <div className='allproject_shadow_card  shadow_1'>
                                                    <div className='d-flex all_project_card_body' >
                                                        <div className='project_all_cards p-2' style={{ display: "flex", justifyContent: "space-between", width: "1000px !important" }}>
                                                            <div>
                                                                <h4 className='px-3 py-1'>{data.projectName.slice(0, 20)}</h4>
                                                                <h4 className='px-3 py-1'>{data.clientName}</h4>

                                                            </div>
                                                            <div >

                                                                <h6 className='px-3 py-1'>{data.edate.slice(0, 10)}</h6>
                                                                <h6 className='px-3 py-1'>{data.state}</h6>

                                                            </div>
                                                        </div>
                                                        {/* <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Link></>
                                    )
                                })
                                :
                                <>

                                    {
                                        HunprojectsCard?.map((data, i) => {
                                            return (<Link key={i} to={`/projectList/${data.project_ID}`} style={{ textDecoration: 'none' }}>

                                                <div className='allproject_shadow_card  shadow_1'>
                                                    <div className='d-flex all_project_card_body' >

                                                        <div className='project_all_cards p-2' style={{ display: "flex", justifyContent: "space-between", width: "1000px" }}>
                                                            <div >
                                                                <h4 className='px-3 py-1'>{data.projectName.slice(0, 20)}</h4>
                                                                <h4 className='px-3 py-1'>{data.clientName}</h4>

                                                            </div>
                                                            <div >

                                                                <h6 className='px-3 py-1'>{data.edate.slice(0, 10)}</h6>
                                                                <h6 className='px-3 py-1'>{data.state}</h6>

                                                            </div>    </div>
                                                        {/* <div className='project_imgbox'>
                                                            <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Link>)
                                        })
                                    }
                                    {/* <Link to={`/projectList/${projectsCard[0]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                        <div className='allproject_shadow_card  shadow_1'>
                                            <div className='d-flex all_project_card_body' >
                                                <div className='project_all_cards'>
                                                    <h4 className='px-3 py-1'>{projectsCard[0]?.projectName.slice(0, 20)}</h4>
                                                    <p className='px-3 py-1'>{projectsCard[0]?.edate.slice(0, 10)}</p>
                                                </div>
                                                <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={`/projectList/${projectsCard[1]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                        <div className='allproject_shadow_card shadow_2'>
                                            <div className='d-flex all_project_card_body' >
                                                <div className='project_all_cards'>
                                                    <h4 className='px-3 py-1'>{projectsCard[1]?.projectName.slice(0, 20)}</h4>
                                                    <p className='px-3 py-1'>{projectsCard[1]?.edate.slice(0, 10)}</p>
                                                </div>
                                                <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link style={{ textDecoration: 'none' }} to={`/projectList/${projectsCard[2]?.project_ID}`}>
                                        <div className='allproject_shadow_card shadow_3'>
                                            <div className='d-flex all_project_card_body' >
                                                <div className='project_all_cards'>
                                                    <h4 className='px-3 py-1'>{projectsCard[2]?.projectName.slice(0, 20)}</h4>
                                                    <p className='px-3 py-1'>{projectsCard[2]?.edate.slice(0, 10)}</p>
                                                </div>
                                                <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={`/projectList/${projectsCard[3]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                        <div className='allproject_shadow_card shadow_4'>
                                            <div className='d-flex all_project_card_body' >
                                                <div className='project_all_cards'>
                                                    <h4 className='px-3 py-1'>{projectsCard[3]?.projectName.slice(0, 20)}</h4>
                                                    <p className='px-3 py-1'>{projectsCard[3]?.edate.slice(0, 10)}</p>
                                                </div>
                                                <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={`/projectList/${projectsCard[4]?.project_ID}`} style={{ textDecoration: 'none' }}>
                                        <div className='allproject_shadow_card shadow_5'>
                                            <div className='d-flex all_project_card_body' >
                                                <div className='project_all_cards'>
                                                    <h4 className='px-3 py-1'>{projectsCard[4]?.projectName.slice(0, 20)}</h4>
                                                    <p className='px-3 py-1'>{projectsCard[4]?.edate.slice(0, 10)}</p>
                                                </div>
                                                <div className='project_imgbox'>
                                                    <img src='https://th.bing.com/th?q=Circle+Frame+Glasses+for+Men&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link> */}
                                </>
                        }
                        {

                        }
                    </div >
                </div >

                {/* <div className='main_card_box box_3'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", margin: "auto" }} ><h4 className='mt-3'>Project Name</h4></div>

                    <div className='progress_gap'>
                        <CircularProgressbar
                            value={one}
                            text={`${one}%`}
                            strokeWidth={5}
                        />
                    </div>
                

                </div> */}
            </div >
        </>
    )
}

export default DashDesign