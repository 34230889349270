import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { TiTick } from 'react-icons/ti';
import './AlertBoc.css';
import { useNavigate } from 'react-router-dom';
const SuccessAlertBox = (props) => {
  const navigate = useNavigate();
  const handalClose1 = () => {
    props.setIsOpen1(false);
    if (props.href !== undefined && props.href !== "") {
      var d = props.href.split("/")[1];
      if (props.href.split("/")[props.href.split("/").length - 1] === "type_master") {
        window.location.replace("/manageMaster/element_master");
      }
      else if (props.href.split("/")[props.href.split("/").length - 1] === "element_master") {
        // navigate(-1,{state:{isMsterNav:true}});
        window.location.replace(`/projectList/${props.proId}/ProductionRider`);
      }
      else if (props.href.split("/")[1] === "projectList") {
        window.location.replace(`/projectList/${props.id}`)
      }
      else if (props.href === "/projectList?type=new") {
        window.location.replace(`/projectList?type=new`)
      }
      else if (props.href === "http://localhost:4001/manageMaster/client_master/form?FormProject") {
        window.location.replace(`/projectList/updateform?id=${props.Pid}`)
      }



    }
  }
  return (
    <>
      <Modal className=" fade show" isOpen={props.isOpen1} >
        <ModalHeader className='brdBtm0 text-center pb-0'
        // close={
        // <button className="close" onClick={handalClose}>×</button>}
        >
          <h4 className=' text-center  fw-bold  successColor'><TiTick className='successColor fw-bolder mx-2 h2' />{props.heading1}</h4>
        </ModalHeader>
        <ModalBody className='text-center pt-1'>
          <p className='h6 text-dark'>{props.successMsg}</p>
          <div>   <Button
            className='btnSuccess'
            onClick={handalClose1}
          >
            Ok
          </Button></div>
        </ModalBody>

      </Modal>
    </>
  )
}
export default SuccessAlertBox;
