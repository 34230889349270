
//const API_DOMAIN = "https://topgun-api.showcraftglobal.com/api"; //Production
const API_DOMAIN = "https://showcraft-api.saitec.in/api"; // Dev
//const API_DOMAIN = "http://localhost:13421/api" // Local

//const API_DOMAIN = "http://192.168.1.170:45455/api" // Local

export const LOGIN_API = `${API_DOMAIN}/Account/login`;
export const ADD_PROJECT = `${API_DOMAIN}/Project/createProject`;
export const UPDATE_CLIENT_CONTACT_SHEET = `${API_DOMAIN}/Client/UpdateClientContctSheet`;
export const UPLOAD_IMPREST_SHEET = `${API_DOMAIN}/UploadExcel/UploadImprestExpenseByExcel`;
export const DELETE_PRO_CAT = `${API_DOMAIN}/ProCat/deleteCat`;
export const GET_PROJECT = `${API_DOMAIN}/Project/getProjects`;
export const GET_PROJECT_COUNT = `${API_DOMAIN}/Project/getAllProjectCount`;
export const GET_ALL_PROJECT = `${API_DOMAIN}/Project/getAllProjects`;
export const UNREJECT_PROJECT = `${API_DOMAIN}/Project/UnRejectProject`;
export const GET_PROJECT_BY_DATE = `${API_DOMAIN}/Project/getCustomProjects`;
export const GET_PROJECTBYID = `${API_DOMAIN}/Project/getProjectByID`;
export const INSERT_BRANDSHEET = `${API_DOMAIN}/BrandSheet/createBrandSheet`;
export const UPDATE_PROJECT = `${API_DOMAIN}/Project/updateProject`;// Intrested or not
export const GET_EVENT = `${API_DOMAIN}/Project/GetEvent`;
export const GET_EVENTBYID = `${API_DOMAIN}/Project/getEventByID`;
export const GET_ALLTODO = `${API_DOMAIN}/Project/getAllTodo`;
export const CREATE_TODO = `${API_DOMAIN}/Project/createtodo`;
export const EDIT_TODO = `${API_DOMAIN}/Project/EditTodoByMcode`;
export const Delete_TODO = `${API_DOMAIN}/Project/DeleteTodoByMcode`;
export const GET_EVENT_BY_DATE = `${API_DOMAIN}/Project/getCustomEvents`;
export const INSERT_COST = `${API_DOMAIN}/Project/insertcost`;
export const CHOOSE_DOCUMENT = `${API_DOMAIN}/Document/getDocument`;
export const GET_FILINGPROJECT = `${API_DOMAIN}/Project/getFilingProject`;
//----for all new projects-----------
export const GET_allprojects = `${API_DOMAIN}/Project/getAllNewProjects`;

export const GET_ALL_RESULT_AWAITED = `${API_DOMAIN}/Project/getAllResultAwaitedProjects`;
//----for all filing projects--------
export const GET_fillingprojects = `${API_DOMAIN}/Project/getAllFillingProjects`; //new projects for dahboard tbl
//----for all running projects-----------
export const GET_runningprojects = `${API_DOMAIN}/Project/getAllRunningProjects`;
//----for all completed projects-----------
export const GET_completeprojects = `${API_DOMAIN}/Project/getAllCompletedProjects`;
//----for all rejected projects-----------
export const GET_rejectedProjects = `${API_DOMAIN}/Project/GetAllRejectedProjects`;
//----for all Draft Projects List
export const GET_DraftProjects = `${API_DOMAIN}/Project/getAllDraftProjects`;
//----for all NEW ARRIVAL Projects List
export const GET_NEW_ARRVAL_PRO = `${API_DOMAIN}/Project/GetNewArrivalProjects`;

export const CREATE_CLIENT = `${API_DOMAIN}/Client/createClient`;

export const DELETE_IMPREST = `${API_DOMAIN}/Project/DeleteImprestExpense`;

export const DELETE_TRAVEL = `${API_DOMAIN}/Project/DeleteTravelExpense`;
export const GET_CLIENT = `${API_DOMAIN}/Client/getClient`;
export const GET_PRO_CLIENT_CONTACT = `${API_DOMAIN}/Client/getClientContactSheetByPro`;
export const UPDATE_CLIENT = `${API_DOMAIN}/Client/updateClient`;
export const DELETE_CLIENT = `${API_DOMAIN}/Client/deleteClient`;
export const GET_CLIENT_BY_DATE = `${API_DOMAIN}/Client/getCustomClient`;
export const GET_Employee = `${API_DOMAIN}/Employee/GetEmployee`;
export const CREATE_EMPLOYEE = `${API_DOMAIN}/Employee/createNewEmployee`;
export const GET_EMPLOYEE_BY_DATE = `${API_DOMAIN}/Employee/getCustomEmployee`;
export const DELETE_EMPLOYEE = `${API_DOMAIN}/Employee/DeleteEmployee`;
export const CREATE_DOCUMENT = `${API_DOMAIN}/Document/createDocument`;
export const GET_DOCUMENT = `${API_DOMAIN}/Document/getDocument`;
export const DELETE_DOCUMENT = `${API_DOMAIN}/Document/deleteDocument`;
export const GET_DOCUMENT_BY_DATE = `${API_DOMAIN}/Document/getCustomDocument`;
export const GET_DEPARTMENT = `${API_DOMAIN}/Department/getDepartment`;
export const CREATE_DEPARTMENT = `${API_DOMAIN}/Department/createDepartment`;
export const GET_HEADS = `${API_DOMAIN}/ExpenseHead/getExpenseHead`;
export const GET_HEADS_BY_DATE = `${API_DOMAIN}/ExpenseHead/getCustomExpenseHead`;
export const CREATE_HEADS = `${API_DOMAIN}/ExpenseHead/createExpenseHead`;
export const DELETE_HEADS = `${API_DOMAIN}/ExpenseHead/deleteExpenseHead`;
export const UPDATE_HEADS = `${API_DOMAIN}/ExpenseHead/updateExpenseHead`
export const COSTSHEET_EXCEL = `${API_DOMAIN}/UploadExcel/readCostSheetExcel`;
export const BRANDSHEET_EXCEL = `${API_DOMAIN}/UploadExcel/readBrandSheetExcel`;
export const CREATE_TEAM = `${API_DOMAIN}/Project/createProjectTeam`;
export const GET_TEAM = `${API_DOMAIN}/Project/getAllProjectTeams`;
export const GET_VENDOR = `${API_DOMAIN}/Vendor/getVendor`;
export const GET_VENDOR_BY_DATE = `${API_DOMAIN}/Vendor/getCustomVendor`;
export const CREATE_VENDOR = `${API_DOMAIN}/Vendor/createVendor`;
export const GET_VENDOR_BY_ID = `${API_DOMAIN}/Vendor/getVendorByID`;
export const UPDATE_VENDOR = `${API_DOMAIN}/Vendor/updateVendor`;
export const DELETE_VENDOR = `${API_DOMAIN}/Vendor/deleteVendor`;
export const CREATE_DAILYUPDATE = `${API_DOMAIN}/Project/createDailyUpdate`;
export const GET_DAILYUPDATE = `${API_DOMAIN}/Project/getDailyUpdatesByID`;
export const GET_ALLDAILYUPDATE = `${API_DOMAIN}/Project/getAllDailyUpdates`;
export const GET_ALLDAILY_UPDATE_BY_DATE = `${API_DOMAIN}/Project/getCustomDailyUpdates`;
export const CREATE_EVENTVENDOR = `${API_DOMAIN}/Project/createEventVendor`;
export const GET_ALLVENDOR = `${API_DOMAIN}/Project/getAllEventVendor`;
export const UPDATE_EMPLOYEE = `${API_DOMAIN}/Employee/UpdateEmployee`;
export const UPDATE_PROJECTV2 = `${API_DOMAIN}/Project/updateProjectV2`;
export const GETALL_PROJECT_EVENT = `${API_DOMAIN}/Project/getProjectByIDV2`;
export const DELETE_PROJECT = `${API_DOMAIN}/Project/deleteProject`;
export const GET_EVENTNAMEBYID = `${API_DOMAIN}/Project/getEventNameByID`;
export const GET_EMPLOYEEBYID = `${API_DOMAIN}/Employee/GetEmployeeByID`;
export const GET_DOCUMENTBYID = `${API_DOMAIN}/Document/getDocumentByID`;
export const GET_CLIENTBYID = `${API_DOMAIN}/Client/getClientByID`;
export const GET_HEADBYID = `${API_DOMAIN}/ExpenseHead/getExpenseHeadByID`;
export const GET_EMP_DATAILS_BY_MCODE = `${API_DOMAIN}/Employee/GetEmployeeByMcode`;
export const GET_SEARCH_EMP_DATAILS = `${API_DOMAIN}/Employee/GetSearchEmpByMcode`;
export const GET_TEAMS_BY_PROJECT_ID = `${API_DOMAIN}/Project/getProjectTeamsByProjectID`;
export const GET_VENDOR_BY_PROJECT_BY_ID = `${API_DOMAIN}/Vendor/getVendorByProjectID`;
export const DAILY_UPDATE_BY_EMPLOYEE_ID = `${API_DOMAIN}/Project/getDailyUpdatesByEmployeeID`;
export const DAILY_UPDATE_BY_PROJECT_ID = `${API_DOMAIN}/Project/getDailyUpdatesByID`
export const ASSIGN_COMPANY_DOC = `${API_DOMAIN}/Document/assignDocument`;
export const UNASSIGN_COMPANY_DOC = `${API_DOMAIN}/Document/unassignDocument`;
export const GET_ALL_CMPNY_WORK_DOC = `${API_DOMAIN}/Document/getDocumentMaster`;
export const MERGE_PDF = `${API_DOMAIN}/PDF/mergePDF`;
export const GET_ALL_PROJECTS = `${API_DOMAIN}/Project/getAllProjects`;
export const GET_PROJECT_BY_MCODE = `${API_DOMAIN}/Project/getProjectByEmpMCODE`;
export const APPLY_LEAVES = `${API_DOMAIN}/Employee/createLeaveRecord`;
export const GET_LEAVE = `${API_DOMAIN}/Employee/getLeave`;
export const GET_LEAVE_BY_MCOCE = `${API_DOMAIN}/Employee/getLeaveByEmployeeMcode`;
export const GET_EMERGENCY_CONTACT = `${API_DOMAIN}/Employee/getFamilyDetails`;
export const CREATE_EMERGENCY_CONTACT = `${API_DOMAIN}/Employee/addFamilyDetails`;
export const UPDATE_LEAVE = `${API_DOMAIN}/Employee/updateLeaveRecord`;
export const UPDATE_DOCUMETS = `${API_DOMAIN}/Document/reuploadDocument`;
export const GET_EMERGENCY_DETAILS_BY_MCODE = `${API_DOMAIN}/Employee/getFamilyDetailsByEmployeeID`;
export const Is_PRO_EXIST = `${API_DOMAIN}/Project/checkProjectNameExist`;

//-----------------------API FOR ELEMENT----------------------------------
export const cREATE_ELEMENT_TYPE = `${API_DOMAIN}/Element/CreateElementType`;//GET all UMO 
export const GET_ALL_UMO = `${API_DOMAIN}/Element/getAllUMOType`;//GET all UMO 
export const GET_ELEMENT_TYPE = `${API_DOMAIN}/Element/getElementByID`;//GET TYPE OF ITEM BY ID 
export const GET_ALL_ELEMENTS = `${API_DOMAIN}/Element/getAllElements`;//GET All Element
export const CREATE_ELEMENT = `${API_DOMAIN}/Element/createElement`;//CREATE All Element 
export const CREATE_INDENT_PR = `${API_DOMAIN}/Element/createIndent`;//GET TYPE OF ITEM BY ID 
export const GET_INDENT_PR = `${API_DOMAIN}/Element/getIndentByProID`;//GET TYPE OF ITEM BY ID 
export const DELETE_INDENT_PR = `${API_DOMAIN}/Element/deleteIndent`;//GET TYPE OF ITEM BY ID 
export const GET_INDENT_PR_BY_ID = `${API_DOMAIN}/Element/getIndentByID`;//GETindend TYPE OF ITEM BY ID 
export const UPDATE_INDENT_PR = `${API_DOMAIN}/Element/updateIndent`;//GETindend TYPE OF ITEM BY ID 
//-----------------------API FOR ATTENCANCE----------------------------------
export const ADD_ATTENDANCE = `${API_DOMAIN}/Attendance/insertAttendance`;
export const GET_ATTENDANCE_BY_DATE = `${API_DOMAIN}/Attendance/getAttendanceByDate`;
export const ADD_IN_OUT_TIME = `${API_DOMAIN}/Attendance/AddInOutTime`;
export const GET_ATTENDANCE_OF_USER_BY_DATE = `${API_DOMAIN}/Attendance/getAttendanceOfUserByDate`;
//----------------API for Delete Merged PDF-------------------------------
export const DELETE_MERGED_PDF_DOCUMENT = `${API_DOMAIN}/Document/deleteMergedDocument`;
//----------------API for Support ticket(help)--------------------------------
export const CREATE_SUPPORT_TICKET = `${API_DOMAIN}/Support/createSupportTicket`;
export const GET_ALL_SUPPORT_TICKET = `${API_DOMAIN}/Support/getAllSupportTicket`;
//----------------API for ForgetFassword--------------------------------
export const FORGET_PASSWORD = `${API_DOMAIN}/Account/ForgotPasswrdForAdmin`;
//---------------API for Submit ANS------------------------------------
export const SUBMIT_ANS = `${API_DOMAIN}/Project/submitAns`;
//------------------GET MASTER REPORT ------------------------------------
export const GET_MASTER_REPORT = `${API_DOMAIN}/Project/GetReportsProjects`;
//------------------verify Excel ------------------------------------
export const VERIFY_EXCEL = `${API_DOMAIN}/UploadExcel/verifyCostingExcel`;
//------------------UPLOAD Cost Excel ------------------------------------
export const UPLOAD_COST_JOB_EXCEL = `${API_DOMAIN}/UploadExcel/UploadCostBrandByExcel`;

export const UPLOAD_TRAVEL_EXPENSE_EXCEL = `${API_DOMAIN}/UploadExcel/UploadTravelExpenseByExcel`;
//------------------UPLOAD vendor Excel ------------------------------------
export const UPLOAD_VENDOR_EXCEL = `${API_DOMAIN}/UploadExcel/UploadVendorByExcel`;
//------------------UPLOAD Stock Assest Excel ------------------------------------
export const UPLOAD_STCK_ASST_EXCEL = `${API_DOMAIN}/UploadExcel/UploadStckAsstByExcel`;
//------------------UPLOAD Stock Assest Excel ------------------------------------
export const GET_STCK_ASST_BY_PROID = `${API_DOMAIN}/StockAssest/getStockAsstbyProId`;
//------------------validate vander excel file ------------------------------------
export const VELIDATE_vENDOR_EXCEL = `${API_DOMAIN}/UploadExcel/ValidateVendorExcel`;
//------------------FOR CREATE CATEGORY ------------------------------------
export const CREATE_PRO_CAT = `${API_DOMAIN}/ProCat/createCat`;
//------------------FOR GET CATEGORY ------------------------------------
export const GET_PRO_CAT = `${API_DOMAIN}/ProCat/getAllCat`;
//------------------Count of new projects ------------------------------------
export const NEW_PRO_COUNT = `${API_DOMAIN}/Project/getNewProjectCount`;
//------------------Count of filling projects ------------------------------------
export const FILING_PRO_COUNT = `${API_DOMAIN}/Project/getFillingProCount`;
//------------------Count of running projects ------------------------------------
export const RUNNING_PRO_COUNT = `${API_DOMAIN}/Project/getRunningProCount`;
//------------------Count of completed projects ------------------------------------
export const COMPLETED_PRO_COUNT = `${API_DOMAIN}/Project/getCompletedProCount`;
//------------------Count of rejected projects ------------------------------------
export const REJECTED_PRO_COUNT = `${API_DOMAIN}/Project/getRejectProCount`;
//------------------Count of rejected projects ------------------------------------
export const GET_PRO_DOCUMENT = `${API_DOMAIN}/Document/getProDocByIDAndTyp`;
//------------------get family details by rowpos ------------------------------------
export const GET_EMERGENCY_BY_ID = `${API_DOMAIN}/Employee/getFamilyDetailsById`;
//------------------CREATE Compliance ------------------------------------
export const UPLOAD_COMPLIANCE_EXCEL = `${API_DOMAIN}/UploadExcel/UploadComplianceExcel`;
//------------------get Compliance BY PROJECT ID ------------------------------------
export const GET_COMPLIANCE_EXCEL_BY_PROJECT = `${API_DOMAIN}/UploadExcel/GetComplianceByProID`;

//------------------Delete family details ------------------------------------
export const DELETE_EMERGENCY_DETAILS = `${API_DOMAIN}/Employee/deleteFamilyDetail`;
//------------------UPDATE family details------------------------------------
export const UPDATE_EMERGENCY_CONTACT = `${API_DOMAIN}/Employee/UpdateFamilyDetails`;
//------------------Get Leave Master ------------------------------------
export const GET_LEAVE_MASTER = `${API_DOMAIN}/Employee/GetLeaveMaster`;
//------------------Create Leave Master ------------------------------------
export const CREATE_LEAVE_MASTER = `${API_DOMAIN}/Employee/CreateLeaveMaster`;
//------------------Create Leave Master By rowpos------------------------------------
export const GET_LEAVE_MASTER_BY_ROWPOS = `${API_DOMAIN}/Employee/GetLeaveTypeByRowpos`;
//------------------Update Leave Master------------------------------------
export const UPDATE_LEAVE_MASTER = `${API_DOMAIN}/Employee/UpdateLeaveMaster`;
//------------------Delete Leave Master------------------------------------
export const DELETE_LEAVE_MASTER = `${API_DOMAIN}/Employee/DeleteLeaveMaster`;
//------------------CREATE CLIENT CONCST SHEET------------------------------------
export const CREATE_CLIENT_CONTACT_SHEET = `${API_DOMAIN}/Client/CreateClientContctSheet`;
//------------------GET ALL QUALIFICATION DETAILS------------------------------------
export const GET_ALL_QUALIFICATION = `${API_DOMAIN}/Employee/GetaAllEmpQualification`;
//------------------GET ALL QUALIFICATION DETAILS BY USER MCODE---------------------
export const GET_ALL_QUALIFICATION_BY_MCODE = `${API_DOMAIN}/Employee/GetEmpQualificationByMcode`;
//------------------ADD QUALIFICATION DETAILS--------------------------
export const ADD_QUALIFICATION_BY_MCODE = `${API_DOMAIN}/Employee/AddEmpQualification`;
//------------------UPDATE QUALIFICATION DETAILS----------------------
export const UPDATE_QUALIFICATION_BY_MCODE = `${API_DOMAIN}/Employee/UpdateEmpQualification`;
//------------------GET QUALIFICATION DETAILS BY ROWPOS----------------------
export const GET_QUALIFICATION_BY_MCODE = `${API_DOMAIN}/Employee/getEmpQualificationById`;
//------------------DELETE QUALIFICATION DETAILS ----------------------
export const DELETE_QUALIFICATION = `${API_DOMAIN}/Employee/deleteEmpQualification`;
//------------------GET SALARY SLAB DETAILS ----------------------
export const GET_SALARY_SLAB = `${API_DOMAIN}/SalarySlab/getSalarySlab`;
//------------------DELETE SALARY SLAB DETAILS ----------------------
export const DELETE_SALARY_SLAB = `${API_DOMAIN}/SalarySlab/deleteSalrySlab`;
//------------------GET SALARY SLAB DETAILS BY ROWPOS----------------------
export const GET_SALARY_SLAB_BY_ROWPOS = `${API_DOMAIN}/SalarySlab/GetSalarySlabByRowpos`;
//------------------CREATE SALARY SLAB DETAILS----------------------
export const CREATE_SALARY_SLAB = `${API_DOMAIN}/SalarySlab/createSalarySlab`;
//------------------UPDATE SALARY SLAB DETAILS----------------------
export const UPDATE_SALARY_SLAB = `${API_DOMAIN}/SalarySlab/UpdateSalrySlab`;
//------------------GET ALL STATES----------------------
export const GET_ALL_STATES = `${API_DOMAIN}/Parameter/getAllState`;
//------------------GET ALL CITESIES OF STATE----------------------
export const GET_ALL_CITEIES_BY_STATE = `${API_DOMAIN}/Parameter/getAllCitiesbyStateID`;
//------------------GET ALL EMPLOYEE CHANGES----------------------
export const GET_ALL_EMP_CHANGES = `${API_DOMAIN}/Employee/GetAllEmpChng`;
//------------------GET EMPLOYEE CHANGES BY MCODE----------------------
export const GET_EMP_CHANGES_BY_MCODE = `${API_DOMAIN}/Employee/GetEmpChngByMcode`;
//------------------DELETE EMPLOYEE CHANGES----------------------
export const DELETE_EMP_CHANGES = `${API_DOMAIN}/Employee/DeleteEmpChngByMcode`;
//------------------CREATE EMPLOYEE CHANGES----------------------
export const CREATE_EMP_CHANGES = `${API_DOMAIN}/Employee/InsertEmpChng`;
//------------------UPDATE EMPLOYEE CHANGES----------------------
export const UPDATE_EMP_CHANGES = `${API_DOMAIN}/Employee/UpdateEmpChng`;
//------------------GET SALARY STRUCTURE----------------------
export const GET_SALARY_STRUCTURE = `${API_DOMAIN}/PaySalaryStructure/getPaySlryStrture`;
//------------------DELETE SALARY STRUCTURE----------------------
export const DELETE_SLY_STRUCTURE = `${API_DOMAIN}/PaySalaryStructure/DeletePaySlryStrture`;
//------------------CREATE SALARY STRUCTURE----------------------
export const CREATE_SLY_STRUCTURE = `${API_DOMAIN}/PaySalaryStructure/CreatePaySlryStrture`;
//------------------UPDATE SALARY STRUCTURE----------------------
export const UPDATE_SLY_STRUCTURE = `${API_DOMAIN}/PaySalaryStructure/UpdatePaySlryStrture`;
//------------------GET ALL COL NAME----------------------
export const GET_ALL_COL_NAME = `${API_DOMAIN}/PaySalaryStructure/getAllPrintName`;
//---------------GET all employee acc (salary ac, contract ac, advance ac)-------------------
export const GET_ALL_EMP_ACC = `${API_DOMAIN}/Employee/GetAllEmpAC`;
//---------------GET all employee MONTHALY RECORD-------------------
export const GET_MONTHLY_DATA = `${API_DOMAIN}/Employee/GetmonthalyData`;
//---------------GET all employee MONTHALY RECORD-------------------
export const GET_MONTHLY_DATA_BY_ID = `${API_DOMAIN}/Employee/GetmonthalyDataByMcode`;
//---------------Add employee MONTHALY RECORD-------------------
export const ADD_MONTHALY_SALARY = `${API_DOMAIN}/Employee/AddMonthalyData`;
//---------------UPDATE employee MONTHALY RECORD-------------------
export const UPDATE_MONTHALY_SALARY = `${API_DOMAIN}/Employee/UpdateMonthalyData`;
//---------------GET Cost sheet by project id -------------------
export const GET_COSTSHEET_BY_ID = `${API_DOMAIN}/project/getCostByProID`;

export const GET_TRAVEL_EXPENSE = `${API_DOMAIN}/Project/getTravelExpense`;

export const GET_IMPREST_EXPENSE = `${API_DOMAIN}/Project/getImprestExpense`;
//---------------GET Cost sheet by project id -------------------
export const GET_BRANDSHEET_BY_ID = `${API_DOMAIN}/BrandSheet/getBrandSheetByProID`;
//---------------GET Emp Salary By  id -------------------
export const GET_OLD_SRY_BY_ID = `${API_DOMAIN}/Employee/GetEmpSalaryByMcode`;
//---------------GET Emp Reimbursment By id-------------------
export const GET_RMBSMT_ID = `${API_DOMAIN}/Employee/GetReimbersmentByMcode`;
//---------------GET Emp Perk By id-------------------
export const GET_PERK_ID = `${API_DOMAIN}/Employee/GetPerksByMcode`;
//---------------GET Emp dynamic salaryBy id-------------------
export const GET_DynamicSlary_ID = `${API_DOMAIN}/Employee/GetSlryByMcode`;
//---------------GET Emp dynamic salaryBy id-------------------
export const GENERATE_SLY = `${API_DOMAIN}/Employee/generateSalary`;

export const ALLOWED_USERTYPE = {
    "ADMIN": "ADMIN",
    "PRODUCTION": "PRODUCTION",
    "TEAM": "TEAM",
    "ACCOUNTS-LEAD": "ACCOUNTS-LEAD",
    "ACCOUNTS": "ACCOUNTS",
    "PROJECTS-LEAD": "PROJECTS-LEAD",
    "PROJECTS": "PROJECTS",
    "OPERATIONS-LEAD": "OPERATIONS-LEAD",
    "OPERATIONS": "OPERATIONS",
    "CREATIVE-LEAD": "CREATIVE-LEAD",
    "CREATIVE": "CREATIVE",
    "TENDERS-LEAD": "TENDERS-LEAD",
    "TENDERS": "TENDERS"
}

export const ENCRYPTION_KEY = "my-secret-key@123";

//---------projects state----------------
export const TENDER_STATE = {
    "NEW": "NEW",
    "FILING": "FILING",
    "RUNNING": "RUNNING",
    "COMPLETED": "COMPLETED",
    "REJECTED": "REJECTED",
    "LOST": "LOST",
    "DRAFT": "DRAFT",
    "NEW_ARR": "NEW_ARR",
}