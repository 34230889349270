import React, { useEffect, useState } from 'react';
import { GoDashboard } from "react-icons/go";
import { AiFillProject } from "react-icons/ai";
import { SiGoogletagmanager } from "react-icons/si";
import { BsCalendarEventFill, BsFilterLeft, BsBarChartSteps, BsBarChartLine, BsFilterRight, BsBagFill, BsPerson } from "react-icons/bs";
import { MdDashboard } from "react-icons/md"
import Getcookie from '../../modules/login/Getcookie';
import { TbReportSearch } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import dashboardIcon from "../../assets/dashboard.png";
import CoPresentIcon from '@mui/icons-material/CoPresent';

{/* <MdDashboard style={{color:"#fff"}}/> */ }
const LandingPage = () => {
    const location = useLocation();
    const [burger, setburger] = useState(true)
    const [plus, setplus] = useState("menuListingItems")
    const [minus, setminus] = useState("menuListingItems")

    const mainMenuForAdmin = [
        {
            "name": "Dashboard",
            "link": "/Dashboard",
            "icon": <img src={dashboardIcon} style={{ height: "16px" }} />
        },
        {
            "name": " Masters",
            "link": "/manageMaster/employee_master",
            "icon": <SiGoogletagmanager />
        },
        {
            "name": "Projects",
            "link": "/projectList?type=new",
            "icon": <BsBagFill />
        },


        {
            "name": "Daily Update",
            "link": "/dailyUpdate",
            "icon": <BsCalendarEventFill />
        },
        {
            "name": "Todos",
            "link": "/todos",
            "icon": "✔"
        },
        {
            "name": "Reports",
            "link": "/reports",
            "icon": <TbReportSearch />
        },
        {
            "name": "Profile",
            "link": "/employeeprofile/dashboard",
            "icon": <BsPerson />
        },
    ]
    const mainMenuForUser = [
        {
            "name": "Dashboard",
            "link": "/Dashboard",
            //  "icon":<GoDashboard />
        },
        {
            "name": " Masters",
            "link": "/manageMaster/employee_master",
            "icon": <SiGoogletagmanager />
        },
        {
            "name": "Projects",
            "link": "/projectList?type=new",
            "icon": <AiFillProject />
        },
        {
            "name": "Running Projects",
            "link": "/ItemMaster",
            "icon": <BsCalendarEventFill />
        },
        {
            "name": "Daily Update",
            "link": "/dailyUpdate",
            "icon": <BsCalendarEventFill />
        }
    ]



    // function getCookie() {

    //     let cookie = {};
    //      document.cookie.split(';').forEach(function(el) {
    //       let [key,value] = el.split('=');
    //       cookie[key.trim()] = value;
    //     })
    //     // return cookie ["user"]
    //     // alert(JSON.stringify(cookie["user"]))
    //  if(JSON.stringify(cookie["user"]) === "" ||JSON.stringify(cookie["user"])=== undefined ||JSON.stringify(cookie["user"])=== "undefined" ){
    // window.location.href = "/"
    //  }



    //   }
    let progress = document.getElementById("progressbar")
    let totalheight = document.body.scrollWidth - window.innerWidth;
    window.onscroll = function () {
        let progressheight = (window.pageYOffset / totalheight) * 100;
        progress.style.width = progressheight + "%";
    }
    useEffect(() => {
        // setburger(false)




        //  --------------------------------

        //  document.querySelector('.mainSideBar').style.width = '210px';
        var dashboardWidth = location?.pathname?.slice(1)
        var projectlist = dashboardWidth.slice(0, 11);

        if (dashboardWidth === "Dashboard" || dashboardWidth === "") {
            document.querySelector('.mainSideBar').style.width = '70px'
            document.querySelector('.myview').style.width = '95%'
        }

        else {


            document.querySelector('.myview').style.width = '94%'
            var obj = document.getElementsByClassName('leftNavText')
            for (var i = 0; i < obj.length; i++) {
                obj[i].style.display = 'block'
            }
        }




        //    ------------------------


        setburger(false)
        var obj = document.getElementsByClassName('leftNavText')
        for (var i = 0; i < obj.length; i++) {
            obj[i].style.display = 'none'
        }

        document.querySelector('.mainSideBar').style.width = '70px'
    }, [])
    const handleExpandBurger = () => {

        setburger(false)
        document.querySelector('.mainSideBar').style.width = '70px'

        var dashboardWidth = location?.pathname?.slice(1)

        if (dashboardWidth === "Dashboard") {
            document.querySelector('.myview').style.width = '95%'
            document.body.style.overflow = "hidden";
        }
        else {
            document.querySelector('.myview').style.width = '94.7%'
            document.body.style.overflow = "scroll";
        }

        var obj = document.getElementsByClassName('leftNavText')
        for (var i = 0; i < obj.length; i++) {
            obj[i].style.display = 'none'
        }


    }
    const handlereduceBurger = () => {

        // alert("open")
        setburger(true)
        document.querySelector('.mainSideBar').style.width = '210px'

        var dashboardWidth = location?.pathname?.slice(1)
        if (dashboardWidth === "Dashboard") {
            document.querySelector('.myview').style.width = '95%'
            document.body.style.overflow = "hidden";
        }

        else {
            document.querySelector('.myview').style.width = '99.1%'
            document.body.style.overflow = "scroll";
        }

        var obj = document.getElementsByClassName('leftNavText')
        for (var i = 0; i < obj.length; i++) {
            obj[i].style.display = 'block'
        }

    }


    return (
        <>
            <div id="progressbar"></div>
            <div id="scrollpath "></div>
            <ul className='sideBar' style={{ position: "absolute", boxShadow: "0 0 20px 0 rgba(0,0,0,0.7)", zIndex: "9999" }}>
                {
                    burger ?
                        <li className={plus}>
                            <span style={{ marginLeft: "135px", position: "absolute", zIndex: "9999" }} className='px-2 sidebarspan drawrOpen' onClick={handleExpandBurger} ><BsFilterRight /></span>
                        </li>
                        :
                        <li className={minus}>
                            <span className='px-2 sidebarspan drawrClose' onClick={handlereduceBurger} style={{ position: "absolute", zIndex: "9999" }} ><BsFilterLeft /></span>
                        </li>
                }
                <br />
                {
                    mainMenuForAdmin?.map((data, i) => {
                        return (

                            <li key={i} className=" menuListingItems ">
                                <NavLink to={`${data.link}`} exact="true" activeclassname="active" style={{ textDecoration: 'none' }} className="aInFullWidth py-2">
                                    <span className='px-2 sidebarspan' >{data.icon}  &nbsp;&nbsp;<span className='leftNavText' style={{ width: "138px" }}>{data.name}</span></span>
                                </NavLink>
                            </li>
                        )
                    })
                }
                <li className=" menuListingItems ">
                    <span className='px-2 sidebarspan' >❔</span>
                </li>
            </ul>
        </>
    )
}
export default LandingPage;